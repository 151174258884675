export default {
    arrayMenu: [
        {
            txt: 'Solaire',
            txt_en: 'Solar',
            link: 'solar'
        },
        {
            txt: 'Optique',
            txt_en: 'Optic',
            link: 'optic'
        },
        // {
        //     txt: 'COLLABORATIONS',
        //     link: 'collaborations'
        // },
        {
            txt: 'Anti-lumière bleue',
            txt_en: 'Bluelight',
            link: 'bluelight'
        },
        {
            txt: 'Gaming',
            txt_en: 'Gaming',
            link: 'gaming'
        },
    ]
}